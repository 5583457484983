/**
 * @description 获取url上的参数
 * @param {string} name 参数key
 * @return string | null
 */
export function getUrlParam (name) {
  const href = window.location.href
  const query = href.substring(window.location.href.indexOf('?') + 1, href.length)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] === name) { return pair[1] }
  }
  return null
}
