<template>
  <div class="page">
    <div class="top">
      <div class="left_circle"></div>
      <div class="top_circle"></div>
      <div class="right_circle"></div>
      <div class="college_name">温州医科大学</div>
      <div class="system_name">选寝系统</div>
    </div>
    <div class="flex_center">
      <form class="form flex_column" action="javascript: 0">
        <div class="title">账号登录</div>
        <div class="input_box flex_stretch">
          <input id="code" class="login_input" v-model="num" @input="num = $event.target.value.replace(/\s/g, '')" placeholder="教工号或学号" type="text">
          <div class="cut cut6"></div>
          <label for="code" class="placeholder">教工号或学号</label>
        </div>
        <div class="input_box flex_stretch">
          <input id="pas" class="login_input" v-model="pas" @input="pas = $event.target.value.replace(/\s/g, '')" placeholder="姓名" type="text">
          <div class="cut cut2"></div>
          <label for="pas" class="placeholder">姓名</label>
        </div>
        <div class="flex_1 flex_center">
          <van-button class="gradient_button gradient_button_blue" type="primary" :loading="loading" @click="login">登录</van-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { getUrlParam } from '@/util/public'
import { login, getUrl, getOpenId, getUserInfo } from '@/api/public'

export default {
  name: 'login',
  data () {
    return {
      loading: false,
      num: '',
      pas: '',
      openid: ''
    }
  },
  created () {
    // 先查缓存
    // 查数据库
    // 手动登录
    window.localStorage.clear()
    window.sessionStorage.clear()
    const openid = getUrlParam('openid')
    const code = getUrlParam('code')
    if (code === null && openid === null) {
      // 不存在code 先去获取
      this.getWxCode()
    } else {
      if (openid === null) {
        this.getOpenId(code)
      } else {
        window.localStorage.setItem('openid', openid)
        this.getUserInfo()
      }
    }
  },
  methods: {
    to () {
      const userInfo = window.localStorage.getItem('userInfo')
      let { status, bedId } = JSON.parse(userInfo)
      status = Number(status)
      if (status === -1) {
        // this.$toast.fail('输入教工号或学号及姓名绑定账号')
      }
      // 学生
      if (status === 1) {
        if (bedId === null) {
          this.$router.replace('/dormitory/areaChoose')
        } else {
          this.$router.replace('/dormitory/checkInfo')
        }
      }
      // 教师
      if (status === 2) {
        this.$router.replace('/dormitory/totalStatus')
      }
    },
    getWxCode () {
      getUrl({ url: process.env.VUE_APP_FRONT_HOST }).then(res => {
        const { status, data } = res
        if (status === 200) {
          setTimeout(() => {
            const userInfo = window.localStorage.getItem('userInfo')
            if (userInfo !== null) {
              this.to()
            }
          }, 1000)
          location.replace(data)
        }
      })
    },
    getOpenId (code) {
      getOpenId({ code }).then(res => {
        if (res.data.data.openid) {
          this.openid = res.data.data.openid
          window.localStorage.setItem('openid', res.data.data.openid)
        }
        if (res.data.data.status !== -1) {
          this.getUserInfo()
        }
      })
    },
    getUserInfo () {
      getUserInfo({ openid: window.localStorage.getItem('openid') }).then(res => {
        const { data: r } = res
        const { code, data, msg } = r
        localStorage.setItem('userInfo', JSON.stringify(data))
        localStorage.setItem('token', window.localStorage.getItem('openid'))
        if (code === 0) {
          this.to()
        } else {
          this.$toast.fail(msg)
        }
        this.loading = false
      }).catch(err => { console.log(err) })
    },
    login () {
      this.loading = true
      login({ code: this.num, username: this.pas, openid: window.localStorage.getItem('openid') }).then(res => {
        const { data: r } = res
        const { code, msg } = r
        const successCode = [0, 2, 3, 4, 5]
        if (successCode.indexOf(code) > -1) {
          // 学生
          this.getUserInfo()
        } else {
          this.loading = false
          this.$toast.fail(msg)
        }
      })
      // setTimeout(() => {
      //   this.$router.push('/dormitory/totalStatus')
      //   // this.$router.push('/dormitory/roomChoose')
      // }, 500)
    }
  }
}
</script>

<style scoped lang="less">
@import "../assets/button";
.page {
  position: relative;
  .top {
    position: absolute;
    width: calc(100% - 60px);
    height: 260px;
    background: url("../pic/loginTopBG.png") no-repeat;
    background-size: 100% 100%;
    padding: 0 30px;
    color: #fff;
    .college_name {
      font-size: 27px;
      margin-top: 25px;
    }
    .system_name {
      margin-top: 7px;
      font-size: 18px;
      opacity: 0.66;
    }
    .left_circle {
      width: 127px;
      height: 127px;
      background: url("../pic/loginLeftCircle.png") no-repeat;
      background-size: 100% auto;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%);
    }
    .top_circle {
      width: 19px;
      height: 19px;
      background: url("../pic/loginTopCircle.png") no-repeat;
      background-size: 100% auto;
      position: absolute;
      left: 266px;
      top: 0;
      transform: translateY(-75%);
    }
    .right_circle {
      width: 120px;
      height: 120px;
      background: url("../pic/loginRightCircle.png") no-repeat;
      background-size: 100% auto;
      position: absolute;
      right: 0;
      top: 25px;
      transform: translateX(50%);
    }
  }
  .form {
    width: 320px;
    height: 315px;
    padding: 35px 35px 45px 35px;
    transform: translateY(120px);
    background-image: url("../pic/loginFormBG.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: relative;
    &:before {
      content: "";
      width: 147px;
      height: 141px;
      background: url("../pic/loginBookBg.png") no-repeat;
      background-size: 100% auto;
      right: 10px;
      top: -102px;
      position: absolute;
    }
    .title {
      font-size: 18px;
      color: #2461fb;
      margin-bottom: 25px;
    }
    .input_box {
      width: 100%;
      position: relative;
      margin-bottom: 25px;
      .placeholder {
        font-size: 14px;
        color: #8eaeee;
        font-family: sans-serif;
        left: 20px;
        pointer-events: none;
        position: absolute;
        transform-origin: 0 50%;
        transition: transform 200ms, color 200ms;
        top: 14px;
      }
      .cut {
        background-color: #fff;
        border-radius: 10px;
        height: 20px;
        left: 30px;
        position: absolute;
        top: -20px;
        transform: translateY(0);
        transition: transform 200ms;
        //  字体大小 * 数量 * 0.75 + 16
        &.cut6 {
          width: 79px;
        }
        &.cut2 {
          width: 37px;
        }
      }
      .login_input {
        width: 100%;
        height: 46px;
        padding-left: 20px;
        font-size: 14px;
        color: #8eaeee;
        background: rgba(222, 236, 255, 0.67);
        border-radius: 10px;
        &::placeholder {
          opacity: 0;
        }
      }
    }
    .login_input:focus ~ .cut,
    .login_input:not(:placeholder-shown) ~ .cut {
      transform: translateY(8px);
    }
    .login_input:focus ~ .placeholder,
    .login_input:not(:placeholder-shown) ~ .placeholder {
      transform: translateY(-25px) translateX(18px) scale(0.75);
    }
    //.login_input:not(:placeholder-shown) ~ .placeholder {
    //  color: #808097;
    //}
    //
    //.login_input:focus ~ .placeholder {
    //  color: #dc2f55;
    //}
  }
}
</style>
