import request from '@/util/request'

/**
 * @description 登录
 * @param {string} data.openid
 * @param {string} data.code 学号/教工号
 * @param {string} data.username 姓名
 */
export function login (data) {
  return request({
    url: '/w/boundUser',
    method: 'post',
    data
  })
}

/**
 * @description 获取openid
 * @param {string} data.code
 */
export function getOpenId (data) {
  return request({
    url: '/w/getOpenid',
    method: 'post',
    data: data
  })
}

/**
 * @description 根据openid获取用户信息
 * @param {string} data.openid
 */
export function getUserInfo (data) {
  return request({
    url: '/w/userInfo',
    method: 'post',
    data: data
  })
}

/**
 * @description 根据uid返回用户信息
 * @param {string} data.uid
 */
export function getCasUserInfo (data) {
  return request({
    url: '/cas/api/casLogin',
    method: 'post',
    data: data
  })
}

/**
 * @description 获取code 之后页面跳转到返回的路径
 * @param {string} data.url
 */
export function getUrl (data) {
  return request({
    url: '/w/getUrl',
    method: 'post',
    data: data
  })
}
